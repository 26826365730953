import React from "react";
import SEO from "../components/SEO";
import HeroSection from "@src/components/partials/plus/HeroSection";
import PlusPageContent from "@src/components/partials/plus/PlusPageContent";
import { useLocation } from "@reach/router";

const searchToObject = (search) => {
  const paramArray = search.split("&");
  const paramObject = paramArray.reduce((acc, cur) => {
    const keyValuePair = cur.split("=");
    acc[keyValuePair[0]] = keyValuePair[1];
    return acc;
  }, {});
  return paramObject;
};

const PlusPage: React.FC = () => {
  const location = useLocation();
  const queryParams = searchToObject(location.search.slice(1));
  const SEOProps = {
    title: "directus.page_plus.seo_title",
    description: "directus.page_plus.seo_description",
    twitter: {
      card: "summary_large_image",
      site: "@classdojo",
      creator: "@classdojo",
      title: "Never miss a moment with ClassDojo Plus",
      description: "ClassDojo Plus gives you more ways to support, celebrate and connect with your child.",
      image: "https://static.classdojo.com/img/page_plus/plus-card.png",
    },
  };

  const HeroSectionProps = {
    isBottomCTA: false,
    queryParams: queryParams,
  };

  return (
    <>
      <SEO {...SEOProps} />
      <HeroSection {...HeroSectionProps} />
      <PlusPageContent isBeyondSchoolBanner={false} />
    </>
  );
};

export default PlusPage;
